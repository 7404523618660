import Redux from "../../appRedux/store";
import moment from "moment";
import {CardElement} from "@stripe/react-stripe-js";

export default (state, props) => {
	return new Promise(async (resolve, reject) => {
		const reduxState = Redux().getState();

		if (!state.client) {
			return reject({
				title: "Client Error",
				content: "Please select a client",
			});
		}
		if (!state.coverage) {
			return reject({
				title: "Coverage Error",
				content: "Please select a coverage",
			});
		}
		if (!state.vin) {
			return reject({
				title: "Vin Error",
				content: "Please select a VIN",
			});
		}
		if (!state.year) {
			return reject({
				title: "Year Error",
				content: "Please select a year",
			});
		}
		if (!state.make) {
			return reject({
				title: "Make Error",
				content: "Please select a make",
			});
		}
		if (!state.model) {
			return reject({
				title: "Model Error",
				content: "Please select a model",
			});
		}
		if (!state.from) {
			return reject({
				title: "Date Range Error",
				content: "Please select a starting date",
			});
		}
		if (!state.to) {
			return reject({
				title: "Date Range Error",
				content: "Please select an ending date",
			});
		}
		if (!state.image || (state.extra && !state.imageExtra)) {
			return reject({
				title: "Missing Files",
				content: "Please the vehicle's title",
			});
		}

		const insuranceStart = moment(state.from["toDate"]());
		if (!insuranceStart.isAfter()) {
			return reject({
				title: "Date Range Error",
				content: "This date is in the past. Please double check the time.",
			});
		}

		// Stripe Configuration
		if (props.configuration === "stripe" && !reduxState.auth.credit) {
			props.stripe.createPaymentMethod({
				type: "card",
				card: props.elements.getElement(CardElement),
			}).then(() => {
				return resolve();
			}).catch(error => {
				return reject({
					title: "Stripe Error",
					content: error.message,
				});
			});
		}


		return resolve();
	});
}