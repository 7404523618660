// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const SET_USER_TYPE = "SET_USER_TYPE";

//Client
export const GET_All_CLIENT_SUCCESS = "GET_All_CLIENT_SUCCESS";
export const ON_ADD_CLIENT_SUCCESS = "ON_ADD_CLIENT_SUCCESS";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";

//Car Insurance
export const ON_ADD_CARINSURANCE_SUCCESS = "ON_ADD_CARINSURANCE_SUCCESS";
export const UPDATE_CARINSURANCE_SUCCESS = "UPDATE_CARINSURANCE_SUCCESS";
export const DELETE_CARINSURANCE_SUCCESS = "DELETE_CARINSURANCE_SUCCESS";

//Truck Insurance
export const ON_ADD_TRUCKINSURANCE_SUCCESS = "ON_ADD_TRUCKINSURANCE_SUCCESS";
export const UPDATE_TRUCKINSURANCE_SUCCESS = "UPDATE_TRUCKINSURANCE_SUCCESS";
export const DELETE_TRUCKINSURANCE_SUCCESS = "DELETE_TRUCKINSURANCE_SUCCESS";

// Users
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const GET_All_USER_SUCCESS = "GET_All_USER_SUCCESS";
export const ON_ADD_USER_SUCCESS = "ON_ADD_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

// Employees
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const GET_All_EMPLOYEE_SUCCESS = "GET_All_EMPLOYEE_SUCCESS";
export const ON_ADD_EMPLOYEE_SUCCESS = "ON_ADD_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";

// Agents
export const DELETE_AGENT_SUCCESS = "DELETE_AGENT_SUCCESS";
export const GET_All_AGENT_SUCCESS = "GET_All_AGENT_SUCCESS";
export const ON_ADD_AGENT_SUCCESS = "ON_ADD_AGENT_SUCCESS";
export const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS";

// Capturists
export const DELETE_CAPTURIST_SUCCESS = "DELETE_CAPTURIST_SUCCESS";
export const GET_All_CAPTURIST_SUCCESS = "GET_All_CAPTURIST_SUCCESS";
export const ON_ADD_CAPTURIST_SUCCESS = "ON_ADD_CAPTURIST_SUCCESS";
export const UPDATE_CAPTURIST_SUCCESS = "UPDATE_CAPTURIST_SUCCESS";
//Coverages
export const GET_All_COVERAGE_SUCCESS = "GET_All_COVERAGE_SUCCESS";
export const ON_ADD_COVERAGE_SUCCESS = "ON_ADD_COVERAGE_SUCCESS";
export const UPDATE_COVERAGE_SUCCESS = "UPDATE_COVERAGE_SUCCESS";
export const DELETE_COVERAGE_SUCCESS = "DELETE_COVERAGE_SUCCESS";
