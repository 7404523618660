import React from "react";
import {DatePicker, Modal, Typography} from "antd";
import moment from "moment";

class Dates extends React.Component {
	dateChanged = (e) => {
		if (this.props.state.coverageDays > 0) {
			this.props.onChange({
				from: e,
				to: moment(e).add(this.props.state.coverageDays, "days"),
				days: this.props.state.coverageDays,
				total: this.props.state.coveragePrice,
			});
		} else {
			const diff = e[1]["diff"](e[0], "days", true);
			this.props.onChange({
				dateRange: e,
				from: e[0],
				to: e[1],
				days: Math.ceil(diff),
				total: Math.ceil(diff) * this.props.state.coveragePrice,
			}, () => {
				const insuranceStart = moment(this.props.state.from["toDate"]());
				if (!insuranceStart.isAfter()) {
					Modal.error({
						title: "Date Range Error",
						content: "This date is in the past. Please double check the time.",
					});
				}
			});
		}
	};


	render() {
		return (
			<>
				<Typography.Title level={4}>Dates Covered*</Typography.Title>
				<div className={"gx-form-group"}>
					{
						this.props.state.coverageDays > 0 ?
							<DatePicker
								showTime={{
									hideDisabledOptions: true,
									defaultValue: moment().add(2, "hour"),
								}}
								format={"lll"}
								style={{width: "100%"}}
								placeholder="Start date"
								onChange={this.dateChanged}
								disabledDate={current => current < moment().subtract(1, "day").endOf("day")}
								disabledTime={current => {
									var hours = [];
									for(var i =0; i < moment().add(1, "h").hour(); i++){
										hours.push(i);
									}

									return {
										disabledHours: () => current.isSame(moment(),"d") ? hours : []
									}
								}}
								value={this.props.state.from}
							/>
							:
							<DatePicker.RangePicker
								style={{width: "100%"}}
								showTime={{
									hideDisabledOptions: true,
									defaultValue: [moment().add(2, "hour"), moment().add(2, "hour")],
								}}
								format={"lll"}
								disabledDate={current => current < moment().subtract(1, "day").endOf("day")}
								disabledTime={(current, type) => {
									if (type === "end" || !current || !current.length) return {};

									var hours = [];
									for(var i =0; i < moment().add(1, "h").hour(); i++){
										hours.push(i);
									}

									return {
										disabledHours: () => current[0].isSame(moment(),"d") ? hours : []
									}
								}}
								ranges={{
									"24 Hours": [moment().add(2, "hour"), moment().add(25, "hours")],
									"48 Hours": [moment().add(2, "hour"), moment().add(49, "hours")],
									"72 Hours": [moment().add(2, "hour"), moment().add(73, "hours")],
								}}
								onChange={this.dateChanged}
								value={this.props.state.dateRange}
							/>
					}
				</div>
			</>
		);
	}
}

export default Dates;